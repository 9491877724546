/* eslint-disable react/jsx-no-literals */
import React from 'react'

/**
 * Waiting screen component
 * @param {*} param0 props
 * @returns {*} component
 */
const WaitingScreen = ({
  title, par1, par2, par3
}) => (
  <div className="row justify-content-center">
    <div className="waiting row justify-content-center px-4 mx-2 mx-md-0 py-2 py-md-4 px-md-5">
      <div className="d-flex justify-content-center">
        <div className="apollo mb-4 mt-5 mb-md-3" />
      </div>
      <div className="col-12 align-self-center">
        <h4 className="title mt-3 mb-4 mt-md-3 mb-md-3">{title}</h4>
      </div>
      <div className="align-self-center">
        <p className="waiting-p par1 mb-4 mt-md-3 mb-md-1">{par1}</p>
      </div>
      <div className="align-self-center">
        <p className="waiting-p par2 mb-4 my-md-4">{par2}</p>
      </div>
      <div className="align-self-center">
        <p className="waiting-p mb-5 mt-md-1">{par3}</p>
      </div>
    </div>
  </div>
)

export default WaitingScreen
