import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/sass/moons-ui-kit.scss'
import '@mymoons/ui-library/dist/library.min.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import React from 'react'
import Header from './layout/Header'
import Router from './router'
import Footer from './layout/Footer'
import CustomerProvider from './context/context'

/**
 * main App component
 * @returns {*} component
 */
const App = () => (
  <CustomerProvider>
    <Header />
    <Router />
    <Footer />
  </CustomerProvider>
)

export default App
