/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import affirmLogo from '../assets/img/icon/affirm-logo.svg'

/**
 * Card price component
 * @param {*} param0 props
 * @param {Array} cards list of card object data
 * @returns {*} component
 */
const CardPrice = ({ cards }) => (
  <div>
    {
      cards.map((card, index) => (
        <div
          key={index}
          className="card-price"
        >
          <div className="card-price-content">
            <h6>
              {card.description}
            </h6>
            <h3 className={card.isPriceEmphasys ? 'emphasys' : ''}>
              {card.priceLabel}
            </h3>
            <h6>
              {card.footer.note}
              <span
                className="before"
              >
                {card.footer.beforePrice}
              </span>
              <span className="before-description">
                {card.footer.beforeDescription}
              </span>
            </h6>
          </div>
          {
            card.isPriceEmphasys && (
              <>
                <hr className="affirm-divider" />
                <div className="card-price-content affirm-content">
                  <img
                    className="affirm-logo"
                    src={affirmLogo}
                    alt="affirm-logo"
                  />
                  <span className="affirm-text">
                    {card.footer.affirmDesc}
                  </span>
                </div>
              </>
            )
          }
        </div>
      ))
    }
  </div>
)

export default CardPrice
