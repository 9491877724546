/* eslint-disable react/jsx-no-literals */
import React, { useContext, useEffect } from 'react'
import { CustomerContext } from '../../context/context'
import messages from './NoCandidate.messages'
import BeforeAfter from '../sections/before-after/BeforeAfter'
import NoCandidate from '../../components/NoCandidate'
import TimeGreeting from '../../components/TimeGreeting'

/**
 * Waiting screen page
 * @returns {*} page
 */
const NoCandidatePage = () => {
  const { customer } = useContext(CustomerContext)
  const { name } = customer

  useEffect(() => {
    if (window.MessageBirdChatWidget) {
      window.MessageBirdChatWidget.hide()
    }
  }, [])

  return (
    <div className="container px-4 px-md-0">
      <div className="row">
        <div className="col-12">
          <TimeGreeting
            greeting={messages.title}
            name={name}
          />
          <NoCandidate
            title={messages.noCandTitle}
            title2={messages.noCandTitle2}
            par1={messages.noCandPar1}
            contactLbl={messages.noCandCTA}
            contactLink={messages.noCandLink}
          />
          <div className="d-block mb-5" />
          <div className="waiting-spacer" />
          <BeforeAfter />
          <div className="d-block mb-5" />
          <div className="waiting-spacer" />
        </div>
      </div>
    </div>
  )
}

export default NoCandidatePage
