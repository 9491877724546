/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/jsx-no-literals */
import React, { useEffect, useState } from 'react'
import { Button } from '@mymoons/ui-library'
import ImpressionsSection from '../sections/impressions/ImpressionsSection'
import messages from './MedHistoryPage.messages'
import { hundred } from '../../utils/constants'
import { useMediaQuery } from '../../utils/mediaQuery'

/**
 * Impressions Page component
 * @returns {*} Page layout
 */
const MedHistoryPage = () => {
  const [active, setActive] = useState(false)
  const isMobile = useMediaQuery('(max-width: 767.98px)')

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, hundred)
  }, [])

  useEffect(() => {
    if (window.MessageBirdChatWidget) {
      window.MessageBirdChatWidget.setAttributes({
        pageLoad: 'info_medical_history'
      })
      window.MessageBirdChatWidget.show()
    }
  }, [active])

  return (
    <>
      <div className="container px-4 px-md-0">
        <div className="row no-gutters back-btn-container">
          <Button
            size="medium"
            variant="text"
            color="red"
            label={messages.backLbl}
            iconLeft={messages.backIcon}
            href={messages.backLink}
            target="_self"
            rel="noopener noreferrer"
          />
        </div>
        <div className="row no-gutters">
          <div className="col-12">
            <ImpressionsSection
              miSubtitle={messages.subtitle}
              miTitle={messages.title}
              par1={messages.par1}
              par2P1={messages.par2P1}
              par2P2={messages.par2P2}
              par2Bold={messages.par2Bold}
              par3={messages.par3}
              par4={messages.par4}
              par5P1={messages.par5P1}
              par5P2={messages.par5P2}
              par5Bold={messages.par5Bold}
              par6={messages.par6}
              videoUrl={messages.videoUrl}
              dldPdfLbl={messages.downloadPdfLbl}
              pdfLink={messages.pdfLink}
              aboutProcessTitle={messages.aboutProcessTitle}
              impressionsStepLink={messages.impressionsStepLink}
              impressionsStepLbl={messages.impressionsStepLbl}
              photosStepLink={messages.photosStepLink}
              photosStepLbl={messages.photosStepLbl}
              medHistStepLink={messages.medHistStepLink}
              medHistStepLbl={messages.medHistStepLbl}
              carouselTitle={messages.carouselTitle}
              imageList={messages.imageList}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid mt-3 mt-md-4 more-time-section">
        <div className="container py-2 py-md-4 px-0">
          <div className="row no-gutters">
            <div className="col-12 col-md-6">
              <h4 className="more-time-title mt-5 mb-3">{messages.moreTimeTitle}</h4>
              <p className="more-time-desc mb-4 mb-md-5">{messages.moreTimeDesc}</p>
            </div>
            <div className="col-12 col-md-5 col-lg-4 offset-md-1 offset-lg-2 align-self-center">
              <div className="d-flex justify-content-center mb-5 mb-md-0">
                <Button
                  variant="outline"
                  color="dark"
                  label={messages.moreTimeLbl}
                  href={messages.moreTimeLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  fullWidth={isMobile || false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MedHistoryPage
