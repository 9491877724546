/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { Button } from '@mymoons/ui-library'
import { useMediaQuery } from '../utils/mediaQuery'

/**
 * No Candidate component
 * @returns {*} JSX component
 */
const NoCandidate = ({
  title, title2, par1, contactLbl, contactLink
}) => {
  const isMobile = useMediaQuery('(max-width: 767.98px)')

  return (
    <div className="row justify-content-center">
      <div className="waiting justify-content-center px-4 mx-2 mx-md-0 py-2 py-md-4 px-md-5">
        <div className="d-flex justify-content-center">
          <div className="apollo-no-cand mb-4 mt-5 mb-md-3" />
        </div>
        <div className="col-12 align-self-center">
          <h4 className="title title-no-cand mt-3 mt-md-3">{title}</h4>
        </div>
        <div className="col-12 align-self-center">
          <h4 className="title title-no-cand">{title2}</h4>
        </div>
        <div className="align-self-center">
          <p className="waiting-p par1 par-no-cand">{par1}</p>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="contact"
            size="medium"
            color="dark"
            variant="outline"
            label={contactLbl}
            href={contactLink}
            target="_blank"
            rel="noopener noreferrer"
            fullWidth={isMobile}
          />
        </div>
      </div>
    </div>
  )
}

export default NoCandidate
