/* eslint-disable react/jsx-no-literals */
import React from 'react'
import messages from './BeforeAfterSection.messages'
import testimonials from '../../../utils/testimonials'
import CardsBeforeAfter from '../../../components/CardsBeforeAfter'

/**
 * BeforeAfterSection Component
 * @returns {void} .
 */
const BeforeAfter = () => (
  <div className="SliderTestimony">
    <h3 className="TitleSection mb-4">{messages.title}</h3>
    <h5 className="SubTitleSection">{messages.subTitle}</h5>
    <div className="ContainerCard mt-2">
      {testimonials.map(item => (
        <CardsBeforeAfter
          key={item.id}
          testimony={item}
          beforeLbl={messages.beforeLbl}
          afterLbl={messages.afterLbl}
        />
      ))}
    </div>
  </div>
)

export default BeforeAfter
