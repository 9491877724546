/* eslint-disable no-process-env */
const msgs = {
  backLbl: 'Go back',
  backLink: '/',
  backIcon: 'keyboard_arrow_left',
  moreTimeTitle: 'Need more time?',
  moreTimeDesc: 'Do not worry, you can reschedule the date of collection of the impressions of your teeth.',
  moreTimeLbl: 'Change pickup date',
  moreTimeLink: process.env.REACT_APP_CHANGE_PICKUP_DATE,
  subtitle: 'About Your Treatment',
  title: 'How to fill the medical history form.',
  par1: 'Watch this step by step tutorial and download the user guide before jumping into action.',
  par2P1: 'You have ',
  par2Bold: ' 3 days ',
  par2P2: ' before your medical history is reviewed!',
  par3: 'Watch our medical history tutorial and follow along or read at your own pace downloading our step by step form filling guide.',
  par4: 'Any device has everything you need to take fill your medical history form.',
  par5P1: 'Our board-certified orthodontists use your medical history data to design a customized 3D treatment plan for you. Includes ',
  par5Bold: ' free 3-day ',
  par5P2: ' shipping both ways.',
  par6: 'We\'ll refund you if you\'re not a candidate, and there is no obligation or commitment if you are.',
  videoUrl: process.env.REACT_APP_IMPRESSIONS_VIDEO,
  downloadPdfLbl: 'Download PDF guide',
  pdfLink: process.env.REACT_APP_IMPRESSIONS_PDF,
  aboutProcessTitle: 'About the process',
  impressionsStepLink: '/more-info/impressions',
  impressionsStepLbl: 'Take your impressions',
  photosStepLink: '/more-info/photos',
  photosStepLbl: 'Upload your photos',
  medHistStepLink: '/more-info/medical-history',
  medHistStepLbl: 'Medical history',
  carouselTitle: 'How to fill the medical history form?',
  imageList: [
    {
      key: 'kit-img-1',
      picSrcSet: '/img/impression/impression.webp 1x, /img/impression/impression@2x.webp 2x, /img/impression/impression@3x.webp 3x',
      imgSrcSet: '/img/impression/impression@2x.png 2x, /img/impression/impression@3x.png 3x',
      imgSrc: '/img/impression/impression.png'
    },
    {
      key: 'kit-img-2',
      picSrcSet: '/img/smile/smile.webp 1x, /img/smile/smile@2x.webp 2x, /img/smile/smile@3x.webp 3x',
      imgSrcSet: '/img/smile/smile@2x.png 2x, /img/smile/smile@3x.png 3x',
      imgSrc: '/img/smile/smile.png'
    },
    {
      key: 'kit-img-3',
      picSrcSet: '/img/kit-slider-1/kit-slider-1.webp 1x, /img/kit-slider-1/kit-slider-1@2x.webp 2x, /img/kit-slider-1/kit-slider-1@3x.webp 3x',
      imgSrcSet: '/img/kit-slider-1/kit-slider-1@2x.png 2x, /img/kit-slider-1/kit-slider-1@3x.png 3x',
      imgSrc: '/img/kit-slider-1/kit-slider-1.png'
    },
  ]
}

export default msgs
