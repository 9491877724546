const messages = {
  title: 'One price, two ways to pay',
  subtitle: 'With Moons you can get a beautiful smile AND then spend the thousands of dollars you saved on enjoying your new smile!',
  list: [
    {
      icon: 'smile',
      info: 'Get a beautiful new smile in 4-6 months'
    },
    {
      icon: 'save',
      info: 'Save 80%+ compared to a traditional orthodontic clinic'
    },
    {
      icon: 'check-badge',
      info: 'Smile guarantee: if you don\'t achieve the results predicted, we will ship you more aligners FREE of charge'
    }
  ],
  cards: [
    {
      description: '36-month payment',
      priceLabel: '$34.7/mo',
      isPriceEmphasys: true,
      footer: {
        note: '5% annual APR',
        affirmDesc: 'As an example, $1249.5 purchase in Moons might cost $34.7/mo over 36 months at 5% APR. A down payment may be required'
      }
    },
    {
      description: 'Unique payment',
      priceLabel: '$997',
      isPriceEmphasys: false,
      footer: {
        note: 'Before: ',
        beforePrice: '$1,397',
        beforeDescription: 'Launch offer'
      }
    }
  ]
}

export default messages
