/* eslint-disable no-process-env */
const messages = {
  title: 'Frequently asked questions',
  faqs: [
    {
      title: 'How do invisible aligners work?',
      content: 'With your teeth impressions from the kit, the specialized orthodontic planning team custom design a plan for your smile. We will then send your invisible aligners, they will shift your teeth and align your smile to perfection. You\'ll need to wear your aligners for 22 hours a day, and each aligner set for at least 14 days. Day by day you\'ll be making progress until you finish the treatment and achieve the smile of your dreams.'
    },
    {
      title: 'How do I know if Moons treatment is right for me?',
      content: 'Moons treatment is designed to work with moderate alignment problems, addressing tooth rotation, crowding and spacing cases. Your teeth impressions will give us the information we need to know if you are a good candidate. If you’re not, we’ll refund the full price of the Impression kit.'
    },
    {
      title: 'Is there a guarantee for the treatment or aligners?',
      content: 'Yes, we\'ve got you covered! In case you need additional aligners to achieve the expected smile while doing your treatment, Moons Resuslts Guarantee will cover them. Additionally, once you are done with the treatment, we provide a lifetime smile guarantee to reevaluate your case and provide the necessary treatment to move your teeth again for to the final position. ',
      contentLink: ' Please access here the Guarantees Page for more detail.',
      guarantees: process.env.REACT_APP_GUARANTEES
    },
    {
      title: 'Can Moons treatment help with crowding?',
      content: 'Yes! Crowding is the term used to describe when your teeth overlap. It’s one of the main applications of clear aligners. In case you doubt if Moons treatment is for you, buy the impression kit, and we will use your impressions to design the best plan to help you achieve a smile you love. If we cannot solve your case with Moons treatment, we\'ll refund you the full amount of the impression kit.'
    },
    {
      title: 'Is my impression kit refundable?',
      content: 'At Moons we want you to achieve the smile of your dreams. Your teeth impressions taken at home will give the orthodontic planning team the information we needed to know if you are a good candidate for Moons treatment. In case you’re not a candidate, we’ll refund the full price of the impression kit.'
    }
  ]
}

export default messages
