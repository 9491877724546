/* eslint-disable func-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/no-multi-comp */
import React, { useContext } from 'react'
import AccordionBootstrap from 'react-bootstrap/Accordion'
import AccordionContext from 'react-bootstrap/AccordionContext'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { useAccordionToggle } from 'react-bootstrap'

/**
 * context for accordion
 * @param {*} param0 .
 * @returns {*} Accordion toggle
 */
function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <AccordionBootstrap.Toggle
      as={Button}
      variant="link"
      eventKey={eventKey}
      onClick={decoratedOnClick}
    >
      {children}
      <span className={isCurrentEventKey ? 'expand' : 'contract'} />
    </AccordionBootstrap.Toggle>
  )
}

/**
 * Accordion component
 * @param {*} param0 props
 * @param {Array} faqs list of FAQ object data
 * @returns {*} JSX Component
 */
const Accordion = ({
  faqs
}) => (
  <div className="row">
    <AccordionBootstrap
      defaultActiveKey="0"
      className="col-12 col-sm-8 mx-auto"
    >
      { faqs.map((faq, index) => (
        <Card key={index}>
          <ContextAwareToggle eventKey={index.toString()}>{faq.title}</ContextAwareToggle>
          <AccordionBootstrap.Collapse eventKey={index.toString()}>
            <Card.Body>
              {
                faq.contentLink ? (
                  <>
                    {faq.content}
                    {' '}
                    <a
                      className="d-inline"
                      href={faq.guarantees}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {faq.contentLink}
                    </a>
                  </>
                ) : faq.content
              }
            </Card.Body>
          </AccordionBootstrap.Collapse>
        </Card>
      ))}
    </AccordionBootstrap>
  </div>
)

export default Accordion
