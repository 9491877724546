/* eslint-disable no-process-env */
const msgs = {
  backLbl: 'Go back',
  backLink: '/',
  backIcon: 'keyboard_arrow_left',
  moreTimeTitle: 'Are you ready?',
  moreTimeDesc: 'Do not worry, you can reschedule the date of collection of the impressions of your teeth.',
  moreTimeLbl: 'Upload your photos',
  moreTimeLink: process.env.REACT_APP_PHOTOS_TYPEFORM,
  noPhotosTitle: 'First, send us the impressions of your teeth',
  noPhotosSubtitleBold: 'Once we receive your impressions ',
  noPhotosSubtitle: ' via Fedex, everything will be ready for you to upload your photos.',
  subtitle: 'About Your Treatment',
  title: 'How to take the perfect photos',
  par1: 'Watch this step by step tutorial and read the user guide before taking your pictures. The sooner you complete this last step in the assessment stage, the faster we can determine if you are a candidate for the Moons treatment and the closer you will be to enjoying your new smile.',
  videoUrl: process.env.REACT_APP_PHOTOS_VIDEO,
  downloadPdfLbl: 'Download PDF guide',
  pdfLink: process.env.REACT_APP_PHOTOS_PDF,
  photosSubtitle: 'Take your photos',
  photosListTitle: 'We will need 7 pictures in total:',
  photosSteps: [
    {
      key: 'step1',
      label: '1.- Smiling selfie'
    },
    {
      key: 'step2',
      label: '2.- Serious selfie'
    },
    {
      key: 'step3',
      label: '3.- Picture of the upper row of teeth'
    },
    {
      key: 'step4',
      label: '4.- Picture of the bottom row of teeth'
    },
    {
      key: 'step5',
      label: '5.- Frontal picture using the cheek retractor'
    },
    {
      key: 'step6',
      label: '6.- Left side picture using the cheek retractor'
    },
    {
      key: 'step7',
      label: '7.- Right side picture using the cheek retractor'
    },
  ],
  aboutProcessTitle: 'About the process',
  impressionsStepLink: '/more-info/impressions',
  impressionsStepLbl: 'Take your impressions',
  photosStepLink: '/more-info/photos',
  photosStepLbl: 'Upload your photos',
  medHistStepLink: '/more-info/medical-history',
  medHistStepLbl: 'Medical history',
  imageList: [
    {
      key: 'photos-img-1',
      picSrcSet: '/img/photos-slider-1/photos-slider-1.webp 1x, /img/photos-slider-1/photos-slider-1@2x.webp 2x, /img/photos-slider-1/photos-slider-1@3x.webp 3x',
      imgSrcSet: '/img/photos-slider-1/photos-slider-1@2x.png 2x, /img/photos-slider-1/photos-slider-1@3x.png 3x',
      imgSrc: '/img/photos-slider-1/photos-slider-1.png'
    },
    {
      key: 'photos-img-2',
      picSrcSet: '/img/photos-slider-2/photos-slider-2.webp 1x, /img/photos-slider-2/photos-slider-2@2x.webp 2x, /img/photos-slider-2/photos-slider-2@3x.webp 3x',
      imgSrcSet: '/img/photos-slider-2/photos-slider-2@2x.png 2x, /img/photos-slider-2/photos-slider-2@3x.png 3x',
      imgSrc: '/img/photos-slider-2/photos-slider-2.png'
    },
    {
      key: 'photos-img-3',
      picSrcSet: '/img/photos-slider-3/photos-slider-3.webp 1x, /img/photos-slider-3/photos-slider-3@2x.webp 2x, /img/photos-slider-3/photos-slider-3@3x.webp 3x',
      imgSrcSet: '/img/photos-slider-3/photos-slider-3@2x.png 2x, /img/photos-slider-3/photos-slider-3@3x.png 3x',
      imgSrc: '/img/photos-slider-3/photos-slider-3.png'
    },
    {
      key: 'photos-img-4',
      picSrcSet: '/img/photos-slider-4/photos-slider-4.webp 1x, /img/photos-slider-4/photos-slider-4@2x.webp 2x, /img/photos-slider-4/photos-slider-4@3x.webp 3x',
      imgSrcSet: '/img/photos-slider-4/photos-slider-4@2x.png 2x, /img/photos-slider-4/photos-slider-4@3x.png 3x',
      imgSrc: '/img/photos-slider-4/photos-slider-4.png'
    },
  ]
}

export default msgs
