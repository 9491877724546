export const zero = 0
export const one = 1
export const two = 2
export const hundred = 100
export const twoHundred = 200
export const fiveHun = 500
export const oneHun46 = 146
export const neg1Hun46 = -146
export const twelve = 12
export const eightteen = 18
export const KIT_RECEIVED = 'impression_kit_received'
export const KIT_SCANNED = 'impression_kit_scanned'
export const PHOTOS_UPLOADED = 'photos_uploaded'
export const KIT_PAID = 'impression_kit_paid'
export const KIT_SENT = 'impression_kit_sent'
export const KIT_RETURNED = 'impression_kit_returned'
export const WAITING_PLANNING = 'waiting_planning'
export const PLANNING_READY = 'planning_ready'
export const TREATMENT_PAID = 'treatment_paid'
export const TREATMENT_SENT = 'treatment_sent'
export const TREATMENT_READY = 'treatment_ready'
export const PHOTOS_REJECTED = 'photos_rejected'
export const NO_CANDIDATE = 'no_candidate'
export const MOONS_MAIL_CONTACT = 'contact@welovemoons.com'
export const LS_APP_KEY = 'app'
export const STAGE_QP_PHOTOS = 'photos_uploaded'
export const STAGE_QP_MEDHIST = 'medical_history'
