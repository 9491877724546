/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
import React, { useContext, useEffect } from 'react'
import { CustomerContext } from '../../context/context'
import HomeSteps from '../sections/steps/HomeSteps'
import messages from './Home.messages'
import TimeGreeting from '../../components/TimeGreeting'

/**
 * Home component main
 * @returns {*} JSX Component
 */
const Home = () => {
  const { customer } = useContext(CustomerContext)
  const { name } = customer

  useEffect(() => {
    if (window.MessageBirdChatWidget) {
      window.MessageBirdChatWidget.setAttributes({
        pageLoad: 'dashboard'
      })
      window.MessageBirdChatWidget.show()
    }
  }, [])

  return (
    <div className="container px-4 px-md-0">
      <div className="row">
        <div className="col-12">
          <TimeGreeting
            greeting={messages.title}
            name={name}
          />
          <HomeSteps />
        </div>
      </div>
    </div>
  )
}

export default Home
