/* eslint-disable react/jsx-no-literals */
import React from 'react'
import messages from './bannerMessages'

/**
 * Banner component
 * @returns {*} component
 */
const Banner = () => (
  <div className="banner">
    <div className="container px-4 px-md-0">
      <p className="text">
        {messages.text}
        <a href={`mailto:${messages.email}`}>{messages.email}</a>
      </p>
    </div>
  </div>
)

export default Banner
