/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { twelve, eightteen } from '../utils/constants'

/**
 * Dynamic greeting component
 * @param {*} param0 props
 * @returns {*} JSX component
 */
const TimeGreeting = ({
  hClasses = [], pClasses = [], greeting = 'Good', name = ''
}) => {
  const hour = new Date().getHours()
  return (
    <h1 className={[...hClasses, 'my-5 home-title'].join(' ')}>
      {greeting}
      {` ${(hour < twelve && 'Morning') || (hour < eightteen && 'Afternoon') || 'Evening'}`}
      {
        name && ', '
      }
      {
        name && (
          <p className={[...pClasses, 'd-inline-block customer-name']}>{name}</p>
        )
      }
    </h1>
  )
}

export default TimeGreeting
