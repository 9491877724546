/* eslint-disable react/jsx-no-literals */
import React from 'react'
import Accordion from '../../../components/Accordion'
import messages from './messages'

/**
 * FAQ component
 * @returns {*} component
 */
const Faq = () => (
  <section className="faq">
    <h3 className="text-center">
      {messages.title}
    </h3>
    <Accordion faqs={messages.faqs} />
  </section>
)

export default Faq
