const messages = [
  {
    title: 'Accept your treatment',
    info: 'We need your approval to produce your aligners',
    image: 'apolo-info'
  },
  {
    title: 'Pay your treatment',
    info: 'After your payment we will manufacture and send your aligners',
    image: 'phone-info'
  },
  {
    title: 'Recieve your Moons',
    info: 'Receive your Moons treatment and start improving your smile!',
    image: 'kit-info'
  }
]

export default messages
