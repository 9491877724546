/* eslint-disable react/no-multi-comp */
/* eslint-disable max-statements */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-else-return */
/* eslint-disable complexity */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable no-undefined */
/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useRef, useState, useCallback
} from 'react'
import {
  zero, one, two, fiveHun, oneHun46, neg1Hun46
} from '../utils/constants'

/**
 * Testimonials Menu
 * @returns {void} .
 */
const CardsBeforeAfter = ({ testimony, beforeLbl, afterLbl }) => {
  const [beforeSrc, setBefSrc] = useState(testimony.beforeSrc)
  const [loadedBef, setLoadedBef] = useState(false)
  const [befError, setBefError] = useState(false)
  const [afterSrc, setAfterSrc] = useState(testimony?.afterSrc)
  const [loadedAfter, setLoadedAfter] = useState(false)
  const [afterError, setAfterError] = useState(false)
  const refMove = useRef(null)
  const refImageLeft = useRef(null)
  let mouseDownX = zero
  let XClient = ''
  let oldX = ''

  /**
   * Calculate Slider.
   * @returns {void} .
   */
  const calculateSlider = () => {
    const moverWidth = refMove && refMove.current && refMove.current?.getBoundingClientRect().width
    const widthImage = refImageLeft && refImageLeft.current?.getBoundingClientRect().width
    const heightImage = refImageLeft && refImageLeft.current?.getBoundingClientRect().height

    if (refMove.current) {
      refMove.current.style.left = `${(widthImage / two) - (moverWidth / two)}px`
    }

    if (refImageLeft.current) {
      refImageLeft.current.style.clip = `rect(0px, ${widthImage / two}px, ${heightImage}px, 0px)`
    }
  }

  useEffect(() => {
    if (refMove.current) {
      refMove.current.addEventListener('mousedown', mouseDown)
      refMove.current.addEventListener('mouseup', mouseUp)
      refMove.current.addEventListener('touchstart', touchStart)
      refMove.current.addEventListener('touchend', touchEnd)
      refMove.current.addEventListener('mousemove', mouseMove)
      refMove.current.addEventListener('touchmove', touchMove)
    }

    setTimeout(() => {
      calculateSlider()
    }, fiveHun)

    return () => {
      if (refMove.current) {
        refMove.current.removeEventListener('mousedown', mouseDown)
        refMove.current.removeEventListener('mouseout', mouseUp)
        refMove.current.removeEventListener('touchstart', touchStart)
        refMove.current.removeEventListener('touchend', touchEnd)
        refMove.current.removeEventListener('mousemove', mouseMove)
        refMove.current.removeEventListener('touchmove', touchMove)
      }
    }
  })

  /**
   * mouseDown.
   * @param {Object} event .
   * @returns {void} .
   */
  const mouseDown = (event) => {
    XClient = event.clientX
    mouseDownX = one
  }

  /**
   * mouseUp.
   * @param {Object} event .
   * @returns {void} .
   */
  const mouseUp = (event) => {
    mouseDownX = zero
  }

  /**
   * touchStart.
   * @param {Object} event .
   * @returns {void} .
   */
  const touchStart = (event) => {
    XClient = event.touches[0].clientX
    mouseDownX = one
  }

  /**
   * touchEnd.
   * @param {Object} event .
   * @returns {void} .
   */
  const touchEnd = (event) => {
    mouseDownX = zero
  }

  /**
   * mouseMove.
   * @param {Object} event .
   * @returns {void} .
   */
  const mouseMove = (event) => {
    if (mouseDownX) {
      const XAxis = event.clientX
      const width = refMove && refMove.current.getBoundingClientRect().width
      const height = refMove && refMove.current.getBoundingClientRect().height
      const leftBar = refMove.current.style.left

      if (refMove.current) {
        refMove.current.style.left = `${parseInt(leftBar, 10) + (XAxis - XClient)}px`
      }
      XClient = XAxis
      if (refImageLeft.current) {
        refImageLeft.current.style.clip = `rect(0px, ${width / two + parseInt(leftBar, 10)}px, ${height}px, 0px)`
      }
    }
  }

  /**
   * Direction Move.
   * @param {Object} event .
   * @returns {void} .
   */
  const directionMove = (event) => {
    let direction = ''

    if (event.touches[0].clientX < oldX) {
      direction = 'left'
    } else if (event.touches[0].clientX > oldX) {
      direction = 'right'
    }
    oldX = event.touches[0].clientX
    return direction
  }

  /**
   * touchMove.
   * @param {Object} event .
   * @returns {void} .
   */
  const touchMove = (event) => {
    if (mouseDownX) {
      const width = refMove && refMove.current.getBoundingClientRect().width
      const height = refMove && refMove.current.getBoundingClientRect().height
      const leftBar = refMove.current.style.left
      const dir = directionMove(event)

      if ((parseInt(leftBar, 10) < neg1Hun46 && parseInt(leftBar, 10) !== zero && dir === 'left')
      || (parseInt(leftBar, 10) > oneHun46 && dir === 'right')) {
        mouseDownX = zero
      } else {
        if (refMove.current) {
          refMove.current.style.left = `${parseInt(leftBar, 10) + (event.touches[0].clientX - XClient)}px`
        }
        XClient = event.touches[0].clientX
        if (refImageLeft.current) {
          refImageLeft.current.style.clip = `rect(0px, ${width / two + parseInt(leftBar, 10)}px, ${height}px, 0px)`
        }
      }
    }
  }

  /**
   * Go After.
   * @returns {void} .
   */
  const goAfter = () => {
    const id = setInterval(() => {
      const leftBar = refMove.current.style.left
      const width = refMove && refMove.current.getBoundingClientRect().width
      const height = refMove && refMove.current.getBoundingClientRect().height

      if (parseInt(leftBar, 10) > oneHun46) {
        clearInterval(id)
      } else {
        if (refMove.current) {
          refMove.current.style.left = `${parseInt(leftBar, 10) + two}px`
        }

        if (refImageLeft.current) {
          refImageLeft.current.style.clip = `rect(0px, ${width / two + parseInt(leftBar, 10)}px, ${height}px, 0px)`
        }
      }
    }, one)
  }

  /**
   * Go Before.
   * @returns {void} .
   */
  const goBefore = () => {
    const id = setInterval(() => {
      const leftBar = refMove.current.style.left
      const width = refMove && refMove.current.getBoundingClientRect().width
      const height = refMove && refMove.current.getBoundingClientRect().height

      if (parseInt(leftBar, 10) < neg1Hun46 && parseInt(leftBar, 10) !== zero) {
        clearInterval(id)
      } else {
        if (refMove.current) {
          refMove.current.style.left = `${parseInt(leftBar, 10) - two}px`
        }

        if (refImageLeft.current) {
          refImageLeft.current.style.clip = `rect(0px, ${width / two + parseInt(leftBar, 10)}px, ${height}px, 0px)`
        }
      }
    }, one)
  }

  /**
   * Set default img src and imgError flag to true
   */
  const onBefError = useCallback(() => {
    setBefSrc(testimony.beforeSrc)
    setBefError(true)
  }, [])

  /**
   * Set default img src and imgError flag to true
   */
  const onAfterError = useCallback(() => {
    setAfterSrc(testimony.afterSrc)
    setAfterError(true)
  }, [])

  /**
   * Set img src from Vehicle image prop
   * As did mount
   */
  useEffect(() => {
    setBefSrc(testimony.beforeSrc)
    setAfterSrc(testimony.afterSrc)
  }, [])

  /**
   * Card.
   * @param {String} testimony .
   * @returns {void} .
   */
  const Card = () => (
    <div className="Item">
      <div className="Header">
        <div
          className="ButtonBefore"
          role="button"
          tabIndex={0}
          onClick={() => goBefore()}
        >
          {beforeLbl}
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => goAfter()}
          className="ButtonAfter"
        >
          {afterLbl}
        </div>
        <picture>
          <source
            alt="source-webp"
            srcSet={testimony.beforePicSrcSet}
            type="image/webp"
          />
          <source
            alt="source-png"
            srcSet={testimony.beforeImgSrcSet}
          />
          <img
            ref={refImageLeft}
            src={loadedBef ? befError ? beforeSrc : testimony.beforeSrc : beforeSrc}
            alt="doctor"
            className="Before"
            onError={onBefError}
            onLoad={setLoadedBef.bind(this, true)}
          />
        </picture>
        <picture>
          <source
            alt="source-webp"
            srcSet={testimony.afterPicSrcSet}
            type="image/webp"
          />
          <source
            alt="source-png"
            srcSet={testimony.afterImgSrcSet}
          />
          <img
            src={loadedAfter ? afterError ? afterSrc : testimony.afterSrc : afterSrc}
            alt="doctor"
            className="After"
            onError={onAfterError}
            onLoad={setLoadedAfter.bind(this, true)}
          />
        </picture>
        <div
          ref={refMove}
          className="Bar"
        >
          <div className="IconBefore">
            <svg
              viewBox="0 0 8 20"
              width="20"
              height="20"
            >
              <path
                fillRule="evenodd"
                d="M0 0l8 10-8 10z"
              />
            </svg>
          </div>
          <div className="Divider" />
          <div className="IconAfter">
            <svg
              viewBox="0 0 8 20"
              width="20"
              height="20"
            >
              <path
                fillRule="evenodd"
                d="M0 0l8 10-8 10z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="Body">
        <p className="Name">{testimony.name}</p>
        <p className="Month">{testimony.month}</p>
        <p className="Description">{testimony.description}</p>
      </div>
    </div>
  )

  return (
    <div className="TestimonialsContainer">
      <div className="CardsContainer">
        <Card />
      </div>
    </div>
  )
}

export default CardsBeforeAfter
