/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * About Process component
 * @param {*} param0 props
 * @returns {*} JSX component
 */
const AboutProcess = ({
  title, impressionsLink, impressionsLbl, photosLink, photosLbl,
  medHistLink, medHistLbl
}) => (
  <div className="d-flex flex-column justify-content-start">
    <div className="about-process-title pb-3 mb-4">{title}</div>
    <div className="d-flex flex-row justify-content-between mb-3">
      <Link to={impressionsLink}>
        <span className="about-process-link">{impressionsLbl}</span>
      </Link>
      <Link to={impressionsLink}>
        <span className="material-icons about-process-arrow">
          keyboard_arrow_right
        </span>
      </Link>
    </div>
    <div className="d-flex flex-row justify-content-between mb-3">
      <Link to={photosLink}>
        <span className="about-process-link">{photosLbl}</span>
      </Link>
      <Link to={photosLink}>
        <span className="material-icons about-process-arrow">
          keyboard_arrow_right
        </span>
      </Link>
    </div>
    {/* <div className="d-flex flex-row justify-content-between">
      <Link to={medHistLink}>
        <span className="about-process-link">{medHistLbl}</span>
      </Link>
      <Link to={medHistLink}>
        <span className="material-icons about-process-arrow">
          keyboard_arrow_right
        </span>
      </Link>
    </div> */}
  </div>
)

export default AboutProcess
