/* eslint-disable no-process-env */
const messages = {
  legalList: [
    {
      label: 'Privacy',
      url: process.env.REACT_APP_LEGAL_PRIVACY
    },
    {
      label: 'Terms & conditions',
      url: process.env.REACT_APP_LEGAL_TERMS
    },
    {
      label: 'Refund policy',
      url: process.env.REACT_APP_LEGAL_REFUND
    },
    {
      label: 'Customer consent',
      url: process.env.REACT_APP_CUSTOMER_CONSENT
    },
    {
      label: 'Guarantees page',
      url: process.env.REACT_APP_GUARANTEES
    },
    {
      label: '©2021 Moons'
    }
  ],
  socialList: [
    {
      icon: 'facebook',
      alt: 'Facebook',
      url: 'https://www.facebook.com/welovemoons'
    },
    {
      icon: 'twitter',
      alt: 'Twitter',
      url: 'https://twitter.com/welovemoons?lang=eng'
    },
    {
      icon: 'instagram',
      alt: 'Instagram',
      url: 'https://www.instagram.com/welovemoons'
    },
    {
      icon: 'youtube',
      alt: 'Youtube',
      url: 'https://www.youtube.com/welovemoons'
    }
  ]
}

export default messages
