/* eslint-disable max-statements */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-literals */
import React, { useContext, useMemo } from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import { CustomerContext } from '../../../context/context'
import messages from './HomeSteps.messages'
import Step from '../../../components/Step'
import { STAGE_QP_MEDHIST, STAGE_QP_PHOTOS } from '../../../utils/constants'

/**
 * Home Section
 * @returns {Void} Section
 */
const HomeSteps = () => {
  const { customer } = useContext(CustomerContext)
  const { Status, Email, Customer_First_Name } = customer
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const stage = searchParams.get('stage')

  const showMedHistStep = useMemo(() => {
    const normalpath = Status === messages.tsStatusKey
    if (stage) {
      if (
        stage === STAGE_QP_PHOTOS
        && (Status === messages.ssStatusKey || Status === messages.photosRejectedStatus)
      ) {
        return true
      }
      return normalpath
    }
    return normalpath
  }, [Status, stage])

  const showPhotosStep = useMemo(() => {
    const normalpath = Status === messages.ssStatusKey || Status === messages.photosRejectedStatus
    if (stage) {
      if (stage === STAGE_QP_PHOTOS) {
        return !showMedHistStep
      }
    }
    return normalpath
  }, [
    Status,
    showMedHistStep,
    stage
  ])

  if (stage) {
    if (
      stage === STAGE_QP_MEDHIST && (
        (Status === messages.ssStatusKey || Status === messages.photosRejectedStatus)
        || Status === messages.tsStatusKey
      )
    ) {
      return <Redirect to={`/waiting?stage=${STAGE_QP_MEDHIST}`} />
    }
  }

  return (
    <section>
      <div className="row">
        <div className="col-12 ">
          <Step
            title={messages.titleFirstStep}
            stepNumber={messages.numFirstStep}
            description={messages.descFirstStep}
            firstBullet={messages.fsBulletOne}
            secondBullet={messages.fsBulletTwo}
            thirdBullet={messages.fsBulletThree}
            mainBtn={messages.fsBtn}
            secondBtn={messages.fsSecondaryBtn}
            state={![
              messages.ssStatusKey,
              messages.photosRejectedStatus,
              messages.tsStatusKey
            ].includes(Status)}
            checked={Status === messages.ssStatusKey || Status === messages.tsStatusKey}
            picSrcSet={messages.fsPicSrcSet}
            imgSrcSet={messages.fsImgSrcSet}
            imgSrc={messages.fsImgSrc}
            mainBtnLink={messages.fsMainBtnLink}
          />
        </div>
        <div className="col-12 ">
          <Step
            title={messages.titleSecStep}
            stepNumber={messages.numSecStep}
            description={messages.descSecStep}
            firstBullet={messages.ssBulletOne}
            secondBullet={messages.ssBulletTwo}
            thirdBullet={messages.ssBulletThree}
            mainBtn={messages.ssBtn}
            secondBtn={messages.ssSecondaryBtn}
            state={showPhotosStep}
            checked={Status === messages.tsStatusKey}
            picSrcSet={messages.ssPicSrcSet}
            imgSrcSet={messages.ssImgSrcSet}
            imgSrc={messages.ssImgSrc}
            mainBtnLink={messages.ssMainBtnLink.replace('[emailCustomer]', Email)
              .replace('[nameCustomer]', Customer_First_Name)}
            secondBtnLink={messages.ssSecBtnLink}
          />
        </div>
        <div className="col-12 ">
          <Step
            title={messages.titleThirdStep}
            stepNumber={messages.numThirsStep}
            description={messages.descThirdStep}
            firstBullet={messages.tsBulletOne}
            secondBullet={messages.tsBulletTwo}
            mainBtn={messages.tsBtn}
            secondBtn={messages.tsSecondaryBtn}
            state={showMedHistStep}
            checked={false}
            picSrcSet={messages.tsPicSrcSet}
            imgSrcSet={messages.tsImgSrcSet}
            imgSrc={messages.tsImgSrc}
            mainBtnLink={messages.tsMainBtnLink.replace('[emailCustomer]', Email)
              .replace('[nameCustomer]', Customer_First_Name)}
          />
        </div>
      </div>
    </section>
  )
}

export default HomeSteps
