/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState } from 'react'

export const CustomerContext = React.createContext({
  customer: {},
  changeCustomer: (customer) => {},
  showModal: false,
  setShowModal: (isModal) => {}
})

/**
 * Context Provider
 * @param {*} props .
 * @returns {Void} Provider
 */
const ContextFunction = props => {
  const { children } = props
  const [customerInfo, setCustomerInfo] = useState({})
  const [showModal, setShowModal] = useState(false)

  /**
   * changeCustomer changes customer info in context
   * @param {Object} data Customer's data
   * @returns {Void} changes context
   */
  const changeCustomerInfo = (data) => {
    const newCustomer = { ...data }
    if (newCustomer.Customer_First_Name) {
      newCustomer.name = `${newCustomer.Customer_First_Name} ${newCustomer.Customer_Last_Name || ''}`
    }
    setCustomerInfo(newCustomer)
  }

  return (
    <CustomerContext.Provider
      value={{
        customer: customerInfo,
        changeCustomer: changeCustomerInfo,
        showModal,
        setShowModal
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

export default ContextFunction
