/* eslint-disable no-process-env */
const modalMsgs = {
  titleVerify: 'Verify your identity',
  subtitleVerify: 'To protect your data and continue your process, it is necessary that you enter the email with which you made the purchase of the Home Kit.',
  btnVerify: 'Verify',
  titleError: 'Houston, we have a problem',
  subtitleError: 'Sorry, we couldn\'t find a Moons account associated with the email you entered.',
  linkWebsite: process.env.REACT_APP_USA_WEBSITE,
  lblWebsite: 'Go back to the website',
  lblError: 'Try again',
  inputErrorMsg: 'Required field',
  inputErrorFormat: 'Invalid email format'
}

export default modalMsgs
