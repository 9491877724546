/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import ImageInfo from '../../../components/ImageInfo'
import messages from './listImageInfoMessage'

/**
 * List imag info component
 * @returns {*} component
 */
const ListImageInfo = () => (
  <section className="list-image-info">
    <h3>
      What’s next?
    </h3>
    <div className="row">
      {
        messages.map((message, index) => (
          <div
            className="col-lg-4"
            key={index}
          >
            <ImageInfo
              title={message.title}
              info={message.info}
              image={message.image}
            />
          </div>
        ))
      }
    </div>
  </section>
)

export default ListImageInfo
