/* eslint-disable react/jsx-max-depth */
/* eslint-disable max-params */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import MoreInfoImpressions from '../../../components/MoreInfoImpressions'
import AboutProcess from '../../../components/AboutProcess'
import { one } from '../../../utils/constants'

/**
 * Impression more info section
 * @returns {*} more info impressions section
 */
const ImpressionsSection = ({
  miSubtitle, miTitle, par1, par2P1, par2P2, par2Bold, par3, par4, par5P1, par5P2, par5Bold,
  par6, videoUrl, dldPdfLbl, pdfLink, aboutProcessTitle, impressionsStepLink, impressionsStepLbl,
  photosStepLink, photosStepLbl, medHistStepLink, medHistStepLbl, carouselTitle, imageList,
  par1Bold, par3Bold, photosSubtitle, photosListTitle, photosSteps
}) => (
  <div className="mb-5">
    <div className="row no-gutters mb-5">
      <div className="col-12 col-lg-7 max-width-content">
        <MoreInfoImpressions
          subtitle={miSubtitle}
          title={miTitle}
          par1={par1}
          par1Bold={par1Bold}
          par2P1={par2P1}
          par2P2={par2P2}
          par2Bold={par2Bold}
          par3={par3}
          par3Bold={par3Bold}
          par4={par4}
          par5P1={par5P1}
          par5P2={par5P2}
          par5Bold={par5Bold}
          par6={par6}
          videoUrl={videoUrl}
          btnLabel={dldPdfLbl}
          pdfLink={pdfLink}
          photosSubtitle={photosSubtitle}
          photosListTitle={photosListTitle}
          photosSteps={photosSteps}
        />
      </div>
    </div>
    <div className="sticky-about-process">
      <AboutProcess
        title={aboutProcessTitle}
        impressionsLink={impressionsStepLink}
        impressionsLbl={impressionsStepLbl}
        photosLink={photosStepLink}
        photosLbl={photosStepLbl}
        medHistLink={medHistStepLink}
        medHistLbl={medHistStepLbl}
      />
    </div>
    <div className="row no-gutters mt-2">
      <div className="col-12 col-lg-8 max-width-content">
        {
          carouselTitle && (
            <h4 className="carousel-title mb-4">{carouselTitle}</h4>
          )
        }
        <Carousel
          showThumbs={false}
          showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              className="btn-slider"
              style={{ left: 15 }}
            >
              <span className="material-icons icon-red">
                keyboard_arrow_left
              </span>
            </button>
          )}
          renderArrowNext={(onClickHandler, hasNext, label) => hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              className="btn-slider"
              style={{ right: 15 }}
            >
              <span className="material-icons icon-red">
                keyboard_arrow_right
              </span>
            </button>
          )}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  className="indicator-slider indicator-slider-active"
                  aria-label={`Selected: ${label} ${index + one}`}
                  title={`Selected: ${label} ${index + one}`}
                />
              )
            }
            return (
              <li className="d-inline-block">
                <div
                  className="indicator-slider"
                  role="button"
                  tabIndex={0}
                  value={index}
                  key={index}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  title={`${label} ${index + one}`}
                  aria-label={`${label} ${index + one}`}
                />
              </li>
            )
          }}
        >
          {
            imageList.map(imgElem => (
              <div key={imgElem.key}>
                <picture>
                  <source
                    type="image/webp"
                    srcSet={imgElem.picSrcSet}
                  />
                  <img
                    src={imgElem.imgSrc}
                    className="d-inline-block align-top"
                    srcSet={imgElem.imgSrcSet}
                    alt={imgElem.key}
                  />
                </picture>
              </div>
            ))
          }
        </Carousel>
      </div>
    </div>
  </div>
)

export default ImpressionsSection
