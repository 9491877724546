/* eslint-disable react/jsx-no-literals */
import React, { useContext, useEffect } from 'react'
import { CustomerContext } from '../../context/context'
import messages from './WaitingPage.messages'
import BeforeAfter from '../sections/before-after/BeforeAfter'
import WaitingScreen from '../../components/WaitingScreen'
import TimeGreeting from '../../components/TimeGreeting'

/**
 * Waiting screen page
 * @returns {*} page
 */
const WaitingPage = () => {
  const { customer } = useContext(CustomerContext)
  const { name } = customer

  useEffect(() => {
    if (window.MessageBirdChatWidget) {
      window.MessageBirdChatWidget.hide()
    }
  }, [])

  return (
    <div className="container px-4 px-md-0">
      <div className="row">
        <div className="col-12">
          <TimeGreeting
            greeting={messages.title}
            name={name}
          />
          <WaitingScreen
            title={messages.waitingTitle}
            par1={messages.waitingPar1}
            par2={messages.waitingPar2}
            par3={messages.waitingPar3}
          />
          <div className="d-block mb-5" />
          <div className="waiting-spacer" />
          <BeforeAfter />
          <div className="d-block mb-5" />
          <div className="waiting-spacer" />
        </div>
      </div>
    </div>
  )
}

export default WaitingPage
