/* eslint-disable no-process-env */
const messages = {
  title: 'Good',
  summary: {
    title: 'Your treatment is ready!',
    info: 'We used the Impression Kit to plan your treatment and bring you one step closer to the smile of your dreams'
  },
  acceptAndPayLabel: 'Accept and pay',
  acceptAndPayLink: process.env.REACT_APP_ACCEPT_AND_PAY,
  planningLinkLbl: 'Click here to see your new smile',
  paidLabel: 'Thank you for your payment. Your aligners are on the way!',
  upperAligners: 'Upper aligners',
  lowerAligners: 'Lower aligners',
  treatMonths: 'Months of treatment',
  termsCond: 'By proceeding, you acknowledge that you have read and understood the ',
  termsBtn: 'terms and conditions, ',
  termsLink: process.env.REACT_APP_LEGAL_TERMS,
  privacyAgr: ' and information in the site, and you accept them.',
  privacyBtn: ' privacy agreement',
  privacyLink: process.env.REACT_APP_LEGAL_PRIVACY,
  treatPrice: 'Full treatment',
  touchTitle: 'Want to get in touch?',
  touchDesc: 'We are here for you. Just send us an email and we\'ll get back to you within 24-48 hours.',
  discOneTitle: 'Free retainers',
  discOneDesc: 'Retainers are needed to keep your smile in place after finishing the treatment. The first pair is on us!',
  discTwoTitle: 'Continuous treatment',
  discTwoDesc: 'Wear your Moons aligners 22 hours a day and remove them only for eating and cleaning',
  discThreeTitle: '1 month to accept',
  discThreeDesc: 'Your treatment is valid for the next 30 days. Contact us if more time has gone by',
}

export default messages
