/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/jsx-no-literals */
import React, { useContext, useCallback, useState } from 'react'
import { Button } from '@mymoons/ui-library'
import { CustomerContext } from '../context/context'
import messages from './headerMessages'
import Banner from './Banner'
import { LS_APP_KEY } from '../utils/constants'
import { deleteItemLStorage } from '../services/LocalStorage.service'

/**
 * Header component
 * @returns {*} component
 */
const Header = () => {
  const { setShowModal } = useContext(CustomerContext)
  const [showSanity, setShowSanity] = useState(false)

  const handleLogout = useCallback(() => {
    deleteItemLStorage(LS_APP_KEY)
    setShowSanity(false)
    setShowModal(true)
    window.location = '/'
  }, [setShowModal])

  return (
    <nav className="navbar fixed-top">
      <Banner />
      {
        showSanity && (
          <div className="modal">
            <div className="auth">
              <div className="wrap">
                <div className="content logout-header">
                  <div className="row">
                    <div className="col-12">
                      <p>
                        {messages.logoutLabel}
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => setShowSanity(false)}
                          style={{ display: 'inline' }}
                        >
                          <span className="close" />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="content logout-body">
                  <div className="row">
                    <div className="col-12">
                      <p>{messages.sanityDesc}</p>
                    </div>
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-12 col-md-4">
                      <Button
                        label={messages.cancelLogout}
                        size="medium"
                        color="dark"
                        variant="outline"
                        onClick={() => setShowSanity(false)}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <Button
                        label={messages.confirmLogout}
                        size="medium"
                        color="red"
                        variant="filled"
                        onClick={handleLogout}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className="container px-4 px-md-0">
        <a href="/">
          <img
            src="/logo.svg"
            width="140"
            height="32"
            className="d-inline-block align-top"
            alt="Moons"
          />
        </a>
        <div className="navbar-text my-auto">
          <Button
            label={messages.logoutLabel}
            size="small"
            color="dark"
            variant="outline"
            onClick={() => setShowSanity(true)}
          />
        </div>
      </div>
    </nav>
  )
}

export default Header
