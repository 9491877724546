/* eslint-disable react/jsx-no-literals */
import React, { useEffect } from 'react'
import Faq from '../sections/faq/Faq'
import BeforeAfter from '../sections/before-after/BeforeAfter'
import ListImageInfo from '../sections/listImageInfo/ListImageInfo'
import Price from '../sections/price/Price'
import Checkout from '../sections/checkout/Checkout'

/**
 * Planning page
 * @returns {*} component
 */
const Planning = () => {
  useEffect(() => {
    if (window.MessageBirdChatWidget) {
      window.MessageBirdChatWidget.setAttributes({
        pageLoad: 'planning'
      })
      window.MessageBirdChatWidget.show()
    }
  }, [])

  return (
    <div className="planning container">
      <hr className="space-top" />
      <Checkout />
      <hr />
      <Price />
      <hr />
      <ListImageInfo />
      <hr />
      <BeforeAfter />
      <hr className="space-before-after-bottom" />
      <Faq />
      <hr className="space-bottom" />
    </div>
  )
}

export default Planning
