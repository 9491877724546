/* eslint-disable complexity */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { Button } from '@mymoons/ui-library'

/**
 * Step component
 * @param {Object} param0 props
 * @returns {*} JSX Component
 */
const Step = ({
  title, stepNumber, description, firstBullet, secondBullet,
  thirdBullet, mainBtn, mainBtnLink, secondBtn, secondBtnLink,
  state, checked, picSrcSet, imgSrcSet, imgSrc
}) => (
  <div className="container">
    <div className="row">
      <h4 className={state ? `step-title${checked ? ' check' : ''}` : `step-title${checked ? ' check' : ''} collapsed`}>
        <span>{checked ? '\u00A0' : stepNumber}</span>
        {' '}
        {title}
      </h4>
    </div>
    <div className={state ? 'step-content row mb-5' : 'step-content collapsed row mb-5'}>
      <div className={state ? 'image col-md-12 col-lg-6 col-xl-6' : 'image thumbnail col'}>
        <picture>
          <source
            type="image/webp"
            srcSet={picSrcSet}
          />
          <img
            src={imgSrc}
            srcSet={imgSrcSet}
            alt={`moons-${stepNumber}-step`}
          />
        </picture>
      </div>
      <div className={state ? 'description col-md-12 col-lg-6 col-xl-6' : 'description collapsed col'}>
        <h5>
          {description}
        </h5>
        {
          state && (
            <>
              <ul>
                <li>
                  <span />
                  {firstBullet}
                </li>
                <li>
                  <span />
                  {secondBullet}
                </li>
                {
                  thirdBullet && (
                    <li>
                      <span />
                      {thirdBullet}
                    </li>
                  )
                }
              </ul>
              <Button
                label={mainBtn}
                size="medium"
                href={mainBtnLink}
                target={stepNumber === '1' ? '_self' : '_blank'}
                rel="noopener noreferrer"
              />
              {
                secondBtnLink && (
                  <Button
                    label={secondBtn}
                    size="medium"
                    variant="plain"
                    color="dark"
                    href={secondBtnLink}
                    target={stepNumber === '1' ? '_blank' : '_self'}
                    rel="noopener noreferrer"
                  />
                )
              }
            </>
          )
        }
      </div>
    </div>
  </div>
)

export default Step
