const messages = {
  title: 'Good',
  noCandTitle: 'We are sorry, ',
  noCandTitle2: 'you’re not a candidate for Moons treatment',
  noCandPar1: 'After scanning your impressions, and evaluating your clinical photos and medical history, we are not able to offer you any of Moons\' treatments. Please contact our customer support team at contact@welovemoons.com to manage the refund of your impression kit. You\'ll see it reflected in your account in the following 10-15 days',
  noCandCTA: 'Contact us',
  noCandLink: 'mailto:contact@welovemoons.com'
}

export default messages
