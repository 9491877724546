/* eslint-disable no-process-env */
/* eslint-disable import/prefer-default-export */
import axios from 'axios'

/**
 * Service to get customer data by email
 * @param {String} email customer email
 * @returns {Object} customer data
 */
export const getCustomerData = async (email) => {
  const user = process.env.REACT_APP_API_AUTH_USER
  const password = process.env.REACT_APP_API_AUTH_PASS
  const auth = `${user}:${password}`
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`
  const config = {
    method: 'POST',
    url: process.env.REACT_APP_USA_API,
    data: {
      module: {
        name: 'Sheets',
        dbName: 'Patient'
      },
      data: {
        email
      }
    },
    headers: {
      Authorization: authEncoded
    }
  }
  const resp = await axios(config)
  return resp
}
