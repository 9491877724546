/* eslint-disable camelcase */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/jsx-no-literals */
import React, { useEffect, useState, useContext } from 'react'
import { Button } from '@mymoons/ui-library'
import ImpressionsSection from '../sections/impressions/ImpressionsSection'
import messages from './PhotosPage.messages'
import {
  hundred, zero, KIT_RETURNED, PHOTOS_REJECTED
} from '../../utils/constants'
import { useMediaQuery } from '../../utils/mediaQuery'
import { CustomerContext } from '../../context/context'

/**
 * Impressions Page component
 * @returns {*} Page layout
 */
const PhotosPage = () => {
  window.scrollTo(zero, zero)
  const [active, setActive] = useState(false)
  const isMobile = useMediaQuery('(max-width: 767.98px)')
  const { customer } = useContext(CustomerContext)
  const { Email, Customer_First_Name, Status } = customer

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, hundred)
  }, [])

  useEffect(() => {
    if (window.MessageBirdChatWidget) {
      window.MessageBirdChatWidget.setAttributes({
        pageLoad: 'info_photos'
      })
      window.MessageBirdChatWidget.show()
    }
  }, [active])

  return (
    <>
      <div className="container px-4 px-md-0">
        <div className="row no-gutters back-btn-container">
          <Button
            size="medium"
            variant="text"
            color="red"
            label={messages.backLbl}
            iconLeft={messages.backIcon}
            href={messages.backLink}
            target="_self"
            rel="noopener noreferrer"
          />
        </div>
        <div className="row no-gutters">
          <div className="col-12">
            <ImpressionsSection
              miSubtitle={messages.subtitle}
              miTitle={messages.title}
              par1={messages.par1}
              par2P1={null}
              par2P2={null}
              par2Bold={null}
              par3={null}
              par4={null}
              par5P1={null}
              par5P2={null}
              par5Bold={null}
              par6={null}
              videoUrl={messages.videoUrl}
              dldPdfLbl={messages.downloadPdfLbl}
              pdfLink={messages.pdfLink}
              photosSubtitle={messages.photosSubtitle}
              photosListTitle={messages.photosListTitle}
              photosSteps={messages.photosSteps}
              aboutProcessTitle={messages.aboutProcessTitle}
              impressionsStepLink={messages.impressionsStepLink}
              impressionsStepLbl={messages.impressionsStepLbl}
              photosStepLink={messages.photosStepLink}
              photosStepLbl={messages.photosStepLbl}
              medHistStepLink={messages.medHistStepLink}
              medHistStepLbl={messages.medHistStepLbl}
              carouselTitle={null}
              imageList={messages.imageList}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid mt-3 mt-md-4 more-time-section">
        <div className="container py-2 py-md-4 px-0">
          <div className="row no-gutters">
            {
              Status === KIT_RETURNED || Status === PHOTOS_REJECTED ? (
                <>
                  <div className="col-12 col-md-6">
                    <h3 className="more-time-title mt-5 mb-5">{messages.moreTimeTitle}</h3>
                  </div>
                  <div className="col-12 col-md-5 col-lg-4 offset-md-1 offset-lg-2 align-self-center">
                    <div className="d-flex justify-content-center mb-5 mb-md-0">
                      <Button
                        label={messages.moreTimeLbl}
                        href={messages.moreTimeLink.replace('[emailCustomer]', Email)
                          .replace('[nameCustomer]', Customer_First_Name)}
                        target="_blank"
                        rel="noopener noreferrer"
                        fullWidth={isMobile || false}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12">
                    <h3 className="more-time-title mt-5 mb-4">{messages.noPhotosTitle}</h3>
                    <p className="d-inline photos-page-no-kit-returned">{messages.noPhotosSubtitleBold}</p>
                    <p className="d-inline">{messages.noPhotosSubtitle}</p>
                    <div className="photos-page-span-spacer" />
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default PhotosPage
