/* eslint-disable react/jsx-no-literals */
import React from 'react'
import {
  BrowserRouter as Router, Switch, Route, Redirect
} from 'react-router-dom'
import Home from './pages/home/Home'
import ImpressionsPage from './pages/impressions/ImpressionsPage'
import PhotosPage from './pages/photos/PhotosPage'
import MedHistoryPage from './pages/medicalHistory/MedHistoryPage'
import Modal from './layout/Modal'
import WaitingPage from './pages/waiting/WaitingPage'
import Planning from './pages/planning/Planning'
import NoCandidatePage from './pages/noCandidate/NoCandidate'

/**
 * Router Switch
 * @returns {Void} Component route tree
 */
const Routes = () => (
  <Router>
    <Modal />
    <Switch>
      <Route
        exact
        path="/"
        key="home-page"
        component={Home}
      />
      <Route
        exact
        path="/more-info/photos"
        key="more-info-photos"
        component={PhotosPage}
      />
      <Route
        exact
        path="/more-info/medical-history"
        key="more-info-medical-history"
        component={MedHistoryPage}
      />
      <Route
        exact
        path="/waiting"
        key="waiting-page"
        component={WaitingPage}
      />
      <Route
        exact
        path="/planning"
        key="planning-page"
        component={Planning}
      />
      <Route
        exact
        path="/more-info/impressions"
        key="more-info-impressions"
        component={ImpressionsPage}
      />
      <Route
        exact
        path="/no-candidate"
        key="no-candidate"
        component={NoCandidatePage}
      />
      <Route
        path="/"
        render={() => <Redirect to="/" />}
      />
    </Switch>
  </Router>
)

export default Routes
