/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import messages from './footerMessage'

/**
 * Footer component
 * @returns {*} component
 */
const Footer = () => (
  <footer>
    <div className="container">
      <div className="legal row">
        <div className="col-lg-10 col-md-10 col-6 my-auto">
          <ul>
            {messages.legalList.map((legal, index) => {
              if (!legal.url) {
                return (
                  <li key={index}>
                    {legal.label}
                  </li>
                )
              }
              return (
                <li key={index}>
                  <a
                    href={legal.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {legal.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="col-lg-2 col-md-2 col-6 text-right">
          <picture>
            <source
              type="image/webp"
              srcSet="/img/hipaa/hipaa.webp 1x, /img/hipaa/hipaa@2x.webp 2x, /img/hipaa/hipaa@3x.webp 3x"
            />
            <img
              src="/img/hipaa/hipaa.png"
              width="118"
              height="53"
              className="d-inline-block align-top"
              alt="Moons"
              srcSet="/img/hipaa/hipaa@2x.png 2x, /img/hipaa/hipaa@3x.png 3x"
            />
          </picture>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col mb-0">
          <img
            src="/logo.svg"
            width="106"
            height="24"
            className="svg-invert"
            alt="Moons"
          />
          <ul className="social-list p-0 m-0">
            {messages.socialList.map((social, index) => (
              <li key={index}>
                <a
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    width="24"
                    src={`/${social.icon}.svg`}
                    alt={social.alt}
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
