/* eslint-disable camelcase */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/jsx-no-literals */
import React, { useContext } from 'react'
import { Button } from '@mymoons/ui-library'
import { CustomerContext } from '../../../context/context'
import messages from './messages'
// import VideoSqueare from '../../../components/VideoSqueare'
import { useMediaQuery } from '../../../utils/mediaQuery'
import {
  MOONS_MAIL_CONTACT, TREATMENT_PAID, TREATMENT_READY, TREATMENT_SENT
} from '../../../utils/constants'
import TimeGreeting from '../../../components/TimeGreeting'
// import planningImg from '../../../../public/img/planning/lab-video.png'

/**
 * Checkout section
 * @returns {*} Section
 */
const Checkout = () => {
  const { customer } = useContext(CustomerContext)
  const {
    name, Upper_Aligners, Lower_Aligners, Treatment_Months, Treatment_Price,
    Planning_Link, Status
  } = customer
  const isMobile = useMediaQuery('(max-width: 991.98px)')

  return (
    <section className="checkout">
      <div className="row">
        <div className="col-lg-6">
          <TimeGreeting
            greeting={messages.title}
            name={name}
          />
          <hr />
          <div className="checkout-summary">
            <h5>
              {messages.summary.title}
            </h5>
            {
              isMobile && (
                <a
                  className="position-relative"
                  href={Planning_Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ pointerEvents: Planning_Link ? 'auto' : 'none' }}
                >
                  <img
                    className="planning-lab-video img-responsive"
                    src="/img/planning/lab-video.png"
                    alt="planning-lab-video-link"
                  />
                  <p className="planning-lab-cta">{messages.planningLinkLbl}</p>
                </a>
              )
            }
            {/* {
              isMobile && <VideoSqueare src={Planning_Link} />
            } */}
            <p className="summary-footer">
              {messages.summary.info}
            </p>
          </div>
          <div className="aligners row">
            <div className="upper col-6 col-lg-4 text-center text-lg-left">
              <h4>
                <span>{'\u00A0'}</span>
                {Upper_Aligners}
              </h4>
              <p>
                {messages.upperAligners}
              </p>
            </div>
            <div className="lower col-6 col-lg-4 text-center text-lg-left">
              <h4>
                <span>{'\u00A0'}</span>
                {Lower_Aligners}
              </h4>
              <p>
                {messages.lowerAligners}
              </p>
            </div>
            <div className="treatment-date col-12 col-lg-4 text-center text-lg-left">
              <h4>
                <span>{'\u00A0'}</span>
                {' '}
                {Treatment_Months}
              </h4>
              <p>
                {messages.treatMonths}
              </p>
            </div>
          </div>
          <hr />
          <p className="total">
            {messages.treatPrice}
            {' '}
            <span>
              $
              {Treatment_Price}
            </span>
          </p>
          <hr className="mb-4" />
          <p className="terms-text d-inline">
            {messages.termsCond}
            {' '}
            <span className="d-inline">
              <Button
                className=""
                label={messages.termsBtn}
                size="medium"
                variant="plain"
                color="dark"
                href={messages.termsLink}
                target="_blank"
                rel="noopener noreferrer"
              />
            </span>
            {' '}
            <span className="d-inline">
              <Button
                className=""
                label={messages.privacyBtn}
                size="medium"
                variant="plain"
                color="dark"
                href={messages.privacyLink}
                target="_blank"
                rel="noopener noreferrer"
              />
            </span>
            {' '}
            {messages.privacyAgr}
          </p>
          <Button
            label={[
              TREATMENT_PAID,
              TREATMENT_READY,
              TREATMENT_SENT
            ].includes(Status) ? messages.paidLabel : messages.acceptAndPayLabel}
            size="medium"
            href={messages.acceptAndPayLink}
            target="_blank"
            rel="noopener noreferrer"
            disabled={[
              TREATMENT_PAID,
              TREATMENT_READY,
              TREATMENT_SENT
            ].includes(Status)}
          />
          <div className="card-gray col-12">
            <h5>
              {messages.touchTitle}
            </h5>
            <p>
              {messages.touchDesc}
            </p>
            <a href={`mailto:${MOONS_MAIL_CONTACT}`}>{MOONS_MAIL_CONTACT}</a>
          </div>
          <div className="list-icon-info-simple">
            <h6>
              <span className="retainers">{'\u00A0'}</span>
              {' '}
              {messages.discOneTitle}
            </h6>
            <p>
              {messages.discOneDesc}
            </p>
            <h6>
              <span className="continuous">{'\u00A0'}</span>
              {' '}
              {messages.discTwoTitle}
            </h6>
            <p>
              {messages.discTwoDesc}
            </p>
            <h6>
              <span className="two-months">{'\u00A0'}</span>
              {' '}
              {messages.discThreeTitle}
            </h6>
            <p>
              {messages.discThreeDesc}
            </p>
          </div>
        </div>
        {
          !isMobile && (
            <div className="col-lg-6">
              <a
                className="position-relative"
                href={Planning_Link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ pointerEvents: Planning_Link ? 'auto' : 'none' }}
              >
                <img
                  className="planning-lab-video-desktop img-responsive"
                  src="/img/planning/lab-video.png"
                  alt="planning-lab-video-link"
                />
                <p className="planning-lab-cta">Click here to see your new smile</p>
              </a>
            </div>
          )
        }
      </div>
    </section>
  )
}
export default Checkout
