/* eslint-disable complexity */
/* eslint-disable no-useless-escape */
/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
import React, {
  useContext, useEffect, useState, useCallback
} from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { PageLoader } from '@mymoons/ui-library'
import Auth from '../components/Auth'
import { CustomerContext } from '../context/context'
import { getStoreGlobal, saveInfoLStorage } from '../services/LocalStorage.service'
import { getCustomerData } from '../services/Customer.service'
import messages from './Modal.messages'
import {
  WAITING_PLANNING, PLANNING_READY, LS_APP_KEY, TREATMENT_READY, TREATMENT_SENT,
  TREATMENT_PAID, NO_CANDIDATE
} from '../utils/constants'

/**
 * Modal component
 * checks for LS email and validates user input
 * contains auth section
 * @returns {*} JSX component
 */
const Modal = () => {
  const {
    customer, changeCustomer, showModal, setShowModal
  } = useContext(CustomerContext)
  const [isLoading, setIsLoading] = useState(false)
  const [fsLoading, setFsLoading] = useState(false)
  const [errorMail, setErrorMail] = useState(false)
  const [email, setEmail] = useState('')
  const [inputError, setInputError] = useState('')
  const { Status } = customer
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const stage = searchParams.get('stage')

  useEffect(() => {
    const customerEmail = getStoreGlobal(LS_APP_KEY)
    if (customerEmail) {
      setShowModal(false)
      setFsLoading(true)
      getCustomerData(customerEmail)
        .then(resp => {
          changeCustomer(resp.data)
          setShowModal(false)
          setFsLoading(false)
          freshpaint?.track('login', { email: customerEmail })
        })
        .catch(error => {
          setErrorMail(true)
          setFsLoading(false)
          setShowModal(true)
        })
      return
    }
    setShowModal(true)
    // console.log('LS NOT found mail')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (showModal) {
      document?.body?.classList?.add('no-scroll-modal')
      return
    }
    document?.body?.classList?.remove('no-scroll-modal')
  }, [showModal])

  /**
   * Input validation for email input
   * @returns {Boolean} isvalid
   */
  const validateMail = useCallback(() => {
    if (!email) {
      setInputError(messages.inputErrorMsg)
      return false
    }
    if (!(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i).test(email)) {
      setInputError(messages.inputErrorFormat)
      return false
    }
    setInputError('')
    return true
  }, [email])

  /**
   * Verify email on click and save to LS if valid
   * @returns {Void} save and verify service
   */
  const saveMail = useCallback(async (eventSubmit) => {
    eventSubmit?.preventDefault()
    if (!validateMail()) {
      return
    }
    setIsLoading(true)
    try {
      const emailAux = email
      const customerData = await getCustomerData(emailAux.toLowerCase())
      changeCustomer(customerData.data)
      saveInfoLStorage(LS_APP_KEY, email)
      setIsLoading(false)
      setShowModal(false)
      freshpaint?.track('login', { email })
    } catch (error) {
      // setShowModal(true)
      setErrorMail(true)
      setIsLoading(false)
    }
  }, [
    changeCustomer,
    email,
    setShowModal,
    validateMail
  ])

  if (Status === WAITING_PLANNING) {
    return <Redirect to="/waiting" />
  }

  if (Status === NO_CANDIDATE) {
    return <Redirect to="/no-candidate" />
  }

  if ([
    PLANNING_READY,
    TREATMENT_PAID,
    TREATMENT_READY,
    TREATMENT_SENT
  ].includes(Status)) {
    return <Redirect to="/planning" />
  }

  if (Status && ![PLANNING_READY, WAITING_PLANNING].includes(Status)) {
    if (location.pathname.includes('waiting') || location.pathname.includes('planning')) {
      if (!stage) {
        return <Redirect to="/" />
      }
    }
  }

  return (
    <>
      {
        fsLoading && (
          <PageLoader scrollTop />
        )
      }
      {
        showModal ? (
          <div className="modal">
            <Auth
              title={errorMail ? messages.titleError : messages.titleVerify}
              subtitle={errorMail ? messages.subtitleError : messages.subtitleVerify}
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
              inputError={inputError}
              errorMail={errorMail}
              changeErrorMail={setErrorMail}
              onClickVerify={saveMail}
              lblVerify={messages.btnVerify}
              linkWebsite={messages.linkWebsite}
              lblWebsite={messages.lblWebsite}
              lblTryAgain={messages.lblError}
              loading={isLoading}
            />
          </div>
        ) : null
      }
    </>
  )
}

export default Modal
