/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
const data = [
  {
    id: 0,
    name: 'Andrea, 30',
    month: '9 months of treatment',
    description: 'Don\'t hesitate to make this investment that really changes your life. The service was excellent during my treatment. I love my smile!',
    beforePicSrcSet: '/img/before1/before1.webp 1x, /img/before1/before1@2x.webp 2x, /img/before1/before1@3x.webp 3x',
    beforeImgSrcSet: '/img/before1/before1@2x.png 2x, /img/before1/before1@3x.png 3x',
    beforeSrc: '/img/before1/before1.png',
    afterPicSrcSet: '/img/after1/after1.webp 1x, /img/after1/after1@2x.webp 2x, /img/after1/after1@3x.webp 3x',
    afterImgSrcSet: '/img/after1/after1@2x.png 2x, /img/after1/after1@3x.png 3x',
    afterSrc: '/img/after1/after1.png',

    /*
     * imageBefore: require('../assets/img/png/before1.png').default,
     * imageBeforeWebp: require('../assets/img/webp/before1.webp').default,
     * imageAfter: require('../assets/img/png/after1.png').default,
     * imageAfterWebp: require('../assets/img/webp/after1.webp').default
     */
  },
  {
    id: 1,
    name: 'Brenda, 27',
    month: '9 months of treatment',
    description: 'Incredible treatment, in less than a year my smile is the one I always dreamed of, without pain or discomfort! Moons really gave me the smile of my dreams',
    beforePicSrcSet: '/img/before2/before2.webp 1x, /img/before2/before2@2x.webp 2x, /img/before2/before2@3x.webp 3x',
    beforeImgSrcSet: '/img/before2/before2@2x.png 2x, /img/before2/before2@3x.png 3x',
    beforeSrc: '/img/before2/before2.png',
    afterPicSrcSet: '/img/after2/after2.webp 1x, /img/after2/after2@2x.webp 2x, /img/after2/after2@3x.webp 3x',
    afterImgSrcSet: '/img/after2/after2@2x.png 2x, /img/after2/after2@3x.png 3x',
    afterSrc: '/img/after2/after2.png',

    /*
     * imageBefore: require('../assets/img/png/before2.png').default,
     * imageBeforeWebp: require('../assets/img/webp/before2.webp').default,
     * imageAfter: require('../assets/img/png/after2.png').default,
     * imageAfterWebp: require('../assets/img/webp/after2.webp').default
     */
  },
  {
    id: 2,
    name: 'Carlos, 26',
    month: '6 months of treatment',
    description: 'Wearing invisible aligners has been one of the best decisions I have ever made. Previously, I did not have the confidence I currently have to smile',
    beforePicSrcSet: '/img/before3/before3.webp 1x, /img/before3/before3@2x.webp 2x, /img/before3/before3@3x.webp 3x',
    beforeImgSrcSet: '/img/before3/before3@2x.png 2x, /img/before3/before3@3x.png 3x',
    beforeSrc: '/img/before3/before3.png',
    afterPicSrcSet: '/img/after3/after3.webp 1x, /img/after3/after3@2x.webp 2x, /img/after3/after3@3x.webp 3x',
    afterImgSrcSet: '/img/after3/after3@2x.png 2x, /img/after3/after3@3x.png 3x',
    afterSrc: '/img/after3/after3.png',

    /*
     * imageBefore: require('../assets/img/png/before3.png').default,
     * imageBeforeWebp: require('../assets/img/webp/before3.webp').default,
     * imageAfter: require('../assets/img/png/after3.png').default,
     * imageAfterWebp: require('../assets/img/webp/after3.webp').default
     */
  }
]

export default data
