/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/no-array-index-key */
import React from 'react'

/**
 * list icon info component
 * @param {*} param0 .
 * @returns {*} component
 */
const ListIconInfo = ({ list }) => (
  <div className="list-icon-info row">
    {
      list.map((item, index) => (
        <div
          key={index}
          className="col-sm-12"
        >
          <span
            style={{ backgroundImage: `url(img/icon/${item.icon}.svg)` }}
            className="float-left"
          />
          <p>{item.info}</p>
        </div>
      ))
    }
  </div>
)

export default ListIconInfo
