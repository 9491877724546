/* eslint-disable react/jsx-no-literals */
import React from 'react'
import messages from './messages'
import ListIconInfo from '../../../components/ListIconInfo'
import CardPrice from '../../../components/CardPrice'

/**
 * Price component
 * @returns {*} component
 */
const Price = () => (
  <section className="price row">
    <div className="col-lg-11 mx-auto">
      <div className="row d-flex d-lg-block">
        <div className="col-lg-6 float-left">
          <h2>
            {messages.title}
          </h2>
          <h5>
            {messages.subtitle}
          </h5>
        </div>
        <div className="col-lg-6 float-right">
          <CardPrice cards={messages.cards} />
        </div>
        <div className="col-lg-6">
          <ListIconInfo list={messages.list} />
        </div>
      </div>
    </div>
  </section>
)

export default Price
