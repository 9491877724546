/* eslint-disable func-style */
/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react'

/**
 * use media query hook
 * @param {string} query media query css settings
 * @returns {Boolean} is mobile size
 */
export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }

    /**
     * resize listener
     * @returns {Void} listener
     */
    const listener = () => {
      setMatches(media.matches)
    }
    media.addEventListener('change', listener)
    return () => media.removeEventListener('change', listener)
  }, [matches, query])

  return matches
}
