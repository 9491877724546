/* eslint-disable react/no-array-index-key */
/* eslint-disable complexity */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { Button } from '@mymoons/ui-library'
import { useMediaQuery } from '../utils/mediaQuery'
import tnImpressions from '../assets/img/png/impressions.jpg'
import tnPhotos from '../assets/img/png/photoshoot.jpg'

/**
 * More Info Impressions component data container
 * @returns {*} JSX component
 */
const MoreInfoImpressions = ({
  subtitle, title, par1, par1Bold, par2P1 = null, par3, par4 = null, par5P1 = null, par6,
  videoUrl, par2Bold = null, par2P2 = null, par5Bold, par5P2, btnLabel,
  pdfLink, par3Bold, photosSubtitle, photosListTitle, photosSteps
}) => {
  const isMobile = useMediaQuery('(max-width: 767.98px)')

  return (
    <div className="">
      <div className="d-flex flex-column justify-content-center justify-content-md-start">
        <div className="about-title mb-3">{subtitle}</div>
        <div className="title-more-info-impressions mb-4">{title}</div>
        <p className="p-more-info mb-4">
          {par1}
          {
            par1Bold && (
              <>
                {' '}
                <span className="d-inline">{par1Bold}</span>
              </>
            )
          }
        </p>
        {
          par2P1 && (
            <p className="p-more-info mb-4">
              {par2P1}
              {
                par2Bold && (
                  <span className="d-inline">{par2Bold}</span>
                )
              }
              {par2P2}
            </p>
          )
        }
        <video
          autoPlay={false}
          playsInline
          controls
          src={videoUrl}
          type="video/mp4"
          className="impressions-video mb-4 mt-2"
          poster={photosSubtitle ? tnPhotos : tnImpressions}
        >
          Browser does not support embedded video :(
        </video>
        {
          par3 && (
            <p className="p-more-info mb-4">
              <span className="d-inline">{par3Bold}</span>
              {' '}
              {par3}
            </p>
          )
        }
        {
          par4 && (
            <p className="p-more-info mb-4 mt-2">{par4}</p>
          )
        }
        {
          par5P1 && (
            <p className="p-more-info mb-4">
              {par5P1}
              <span className="p-red d-inline">{par5Bold}</span>
              {par5P2}
            </p>
          )
        }
        {
          par6 && (
            <p className="p-more-info mb-4">{par6}</p>
          )
        }
        <div className="d-flex justify-content-center d-md-block">
          <Button
            size="medium"
            label={btnLabel}
            href={pdfLink}
            target="_blank"
            rel="noopener noreferrer"
            download
            fullWidth={isMobile || false}
          />
        </div>
        {
          photosSubtitle && (
            <h3 className="mi-photos-subtitle">{photosSubtitle}</h3>
          )
        }
        {
          photosListTitle && (
            <h6 className="mi-photos-listTitle">{photosListTitle}</h6>
          )
        }
        {
          photosSteps && photosSteps.map(item => (
            <p
              key={item.key}
              className="mi-photos-listItem"
            >
              {item.label}
            </p>
          ))
        }
      </div>
    </div>
  )
}

export default MoreInfoImpressions
