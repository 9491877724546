/* eslint-disable react/jsx-no-literals */
import React from 'react'

/**
 * image info component
 * @param {*} param0 .
 * @param {String} title title
 * @param {String} info info
 * @param {String} image image name for import
 * @returns {*} component
 */
const ImageInfo = ({
  title, info, image
}) => (
  <div className="image-info row">
    <div className="col-sm-12">
      <span className="float-left">
        <img
          src={`/img/${image}/${image}.png`}
          alt={image}
          srcSet={`
            /img/${image}/${image}@2x.png 2x,
            /img/${image}/${image}@3x.png 3x
          `}
        />
      </span>
      <h5>{title}</h5>
      <p>{info}</p>
    </div>
  </div>
)

export default ImageInfo
