/* eslint-disable no-process-env */
const msgs = {
  backLbl: 'Go back',
  backLink: '/',
  backIcon: 'keyboard_arrow_left',
  moreTimeTitle: 'Done with your impressions?',
  moreTimeDesc1: 'Call 1-800-GoFedEx',
  moreTimeDesc2: ' and use the tracking number included in your return label. If you miss your pickup, please take your impressions to a FedEx drop-off location.',
  moreTimeLbl: 'Call FedEx',
  moreTimeLink: process.env.REACT_APP_FEDEX_NUMBER,
  subtitle: 'About Your Treatment',
  title: 'How to master your impressions',
  par1: 'Watch this step by step tutorial and download the user guide before taking action. Once you\'re done with your impressions, please send them back as soons as possible. Impression material ',
  par1Bold: ' only keeps its properties during the first few days.',
  par2P1: 'Schedule your pick up directly with FedEx!  ',
  par2Bold: ' Call 1-800-GoFedEx ',
  par2P2: ' and use the tracking number included in your return label. If you miss your pickup, please take your impressions to a FedEx drop-off location.',
  par3: ' our impressions tutorial and read our step-by-step guide.',
  par3Bold: 'Watch ',
  par4: 'Our kit has everything you need to send us a complete model of your teeth.',
  par5P1: 'A board-certified orthodontists use your impressions to design a customized treatment plan for you. Includes ',
  par5Bold: ' free shipping ',
  par5P2: ' both ways.',
  par6: 'We\'ll refund you if you\'re not a candidate, and there is no obligation or commitment if you are.',
  videoUrl: process.env.REACT_APP_IMPRESSIONS_VIDEO,
  downloadPdfLbl: 'Download PDF guide',
  pdfLink: process.env.REACT_APP_IMPRESSIONS_PDF,
  aboutProcessTitle: 'About the process',
  impressionsStepLink: '/more-info/impressions',
  impressionsStepLbl: 'Take your impressions',
  photosStepLink: '/more-info/photos',
  photosStepLbl: 'Upload your photos',
  medHistStepLink: '/more-info/medical-history',
  medHistStepLbl: 'Medical history',
  carouselTitle: 'What’s inside the kit?',
  imageList: [
    {
      key: 'kit-img-1',
      picSrcSet: '/img/kit-slider-1/kit-slider-1.webp 1x, /img/kit-slider-1/kit-slider-1@2x.webp 2x, /img/kit-slider-1/kit-slider-1@3x.webp 3x',
      imgSrcSet: '/img/kit-slider-1/kit-slider-1@2x.png 2x, /img/kit-slider-1/kit-slider-1@3x.png 3x',
      imgSrc: '/img/kit-slider-1/kit-slider-1.png'
    },
    {
      key: 'kit-img-2',
      picSrcSet: '/img/kit-slider-2/kit-slider-2.webp 1x, /img/kit-slider-2/kit-slider-2@2x.webp 2x, /img/kit-slider-2/kit-slider-2@3x.webp 3x',
      imgSrcSet: '/img/kit-slider-2/kit-slider-2@2x.png 2x, /img/kit-slider-2/kit-slider-2@3x.png 3x',
      imgSrc: '/img/kit-slider-2/kit-slider-2.png'
    },
    {
      key: 'kit-img-3',
      picSrcSet: '/img/kit-slider-3/kit-slider-3.webp 1x, /img/kit-slider-3/kit-slider-3@2x.webp 2x, /img/kit-slider-3/kit-slider-3@3x.webp 3x',
      imgSrcSet: '/img/kit-slider-3/kit-slider-3@2x.png 2x, /img/kit-slider-3/kit-slider-3@3x.png 3x',
      imgSrc: '/img/kit-slider-3/kit-slider-3.png'
    },
    {
      key: 'kit-img-4',
      picSrcSet: '/img/kit-slider-4/kit-slider-4.webp 1x, /img/kit-slider-4/kit-slider-4@2x.webp 2x, /img/kit-slider-4/kit-slider-4@3x.webp 3x',
      imgSrcSet: '/img/kit-slider-4/kit-slider-4@2x.png 2x, /img/kit-slider-4/kit-slider-4@3x.png 3x',
      imgSrc: '/img/kit-slider-4/kit-slider-4.png'
    },
    {
      key: 'kit-img-5',
      picSrcSet: '/img/kit-slider-5/kit-slider-5.webp 1x, /img/kit-slider-5/kit-slider-5@2x.webp 2x, /img/kit-slider-5/kit-slider-5@3x.webp 3x',
      imgSrcSet: '/img/kit-slider-5/kit-slider-5@2x.png 2x, /img/kit-slider-5/kit-slider-5@3x.png 3x',
      imgSrc: '/img/kit-slider-5/kit-slider-5.png'
    },
  ]
}

export default msgs
