/* eslint-disable no-process-env */
import {
  KIT_RECEIVED, KIT_RETURNED, PHOTOS_UPLOADED, PHOTOS_REJECTED
} from '../../../utils/constants'

const messages = {
  titleFirstStep: 'Take your impressions',
  numFirstStep: '1',
  descFirstStep: 'Taking your impressions is the most critical part of the process. Make sure you read all the instructions and have enough time to do it properly',
  fsBulletOne: 'It usually takes our patients 20 min to take their impressions',
  fsBulletTwo: 'Brush your teeth and floss before you start the process',
  fsBulletThree: 'Have a timer handy to use during the process',
  fsBtn: 'Take impressions',
  fsSecondaryBtn: 'Schedule pickup',
  fsStatusKey: KIT_RECEIVED,
  fsPicSrcSet: '/img/impression/impression.webp 1x, /img/impression/impression@2x.webp 2x, /img/impression/impression@3x.webp 3x',
  fsImgSrcSet: '/img/impression/impression@2x.png 2x, /img/impression/impression@3x.png 3x',
  fsImgSrc: '/img/impression/impression.png',
  fsMainBtnLink: '/more-info/impressions',
  fsSecBtnLink: process.env.REACT_APP_CHANGE_PICKUP_DATE,
  // second
  titleSecStep: 'Upload photos',
  numSecStep: '2',
  descSecStep: 'Taking your photos is of utmost importance! Make sure you read all the instructions, watch the video tutorial, and have enough time to do it properly',
  ssBulletOne: 'Make sure you are in a well-lit environment, and that your camera lens is clean',
  ssBulletTwo: 'Selfies are great but we highly recommend you asking a family member or a friend for help!',
  ssBulletThree: 'You can also use the timer of your camera',
  ssBtn: 'Upload photos',
  ssSecondaryBtn: 'Read the instructions',
  ssStatusKey: KIT_RETURNED,
  photosRejectedStatus: PHOTOS_REJECTED,
  ssPicSrcSet: '/img/smile/smile.webp 1x, /img/smile/smile@2x.webp 2x, /img/smile/smile@3x.webp 3x',
  ssImgSrcSet: '/img/smile/smile@2x.png 2x, /img/smile/smile@3x.png 3x',
  ssImgSrc: '/img/smile/smile.png',
  ssMainBtnLink: process.env.REACT_APP_PHOTOS_TYPEFORM,
  ssSecBtnLink: '/more-info/photos',
  // third
  titleThirdStep: 'Complete medical history',
  numThirsStep: '3',
  descThirdStep: 'To complete your profile and design the best treatment plan for you, please answer the following medical questionnaire',
  tsBulletOne: 'The data you provide will be treated according to our Privacy Policy and data protection laws. By filling out the questionnaire you admit that everything you answer is true and can be used to design your plan',
  tsBulletTwo: 'A team of specialists will assess your case to design your personalized treatment plan',
  tsBtn: 'Complete form',
  tsSecondaryBtn: 'Learn more',
  tsStatusKey: PHOTOS_UPLOADED,
  tsPicSrcSet: '/img/medical-history/medical-history.webp 1x, /img/medical-history/medical-history@2x.webp 2x, /img/medical-history/medical-history@3x.webp 3x',
  tsImgSrcSet: '/img/medical-history/medical-history@2x.png 2x, /img/medical-history/medical-history@3x.png 3x',
  tsImgSrc: '/img/medical-history/medical-history.png',
  tsMainBtnLink: process.env.REACT_APP_MEDHIST_TYPEFORM,
  tsSecBtnLink: '/more-info/medical-history',
}

export default messages
