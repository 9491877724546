/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { Button, Input } from '@mymoons/ui-library'

/**
 * Auth section component
 * @param {*} param0 props
 * @returns {*} JSX component
 */
const Auth = ({
  title, subtitle, value, onChange, errorMail, onClickVerify,
  lblVerify, linkWebsite, lblWebsite, lblTryAgain, loading,
  inputError, changeErrorMail
}) => (
  <div className="auth">
    <div className="wrap">
      <div className="content">
        <div className="row">
          <div className="col-12">
            <div className="logo" />
          </div>
          <div className="col-12">
            <h5 className="color-font-black">
              {title}
            </h5>
          </div>
          <div className="col-12">
            <p>{subtitle}</p>
          </div>
          {
            errorMail ? (
              <>
                <div className="col-12">
                  <Button
                    label={lblWebsite}
                    size="medium"
                    href={linkWebsite}
                    target="_self"
                    rel="noopener noreferrer"
                  />
                </div>
                <div className="col-12">
                  <Button
                    label={lblTryAgain}
                    size="medium"
                    color="dark"
                    variant="textProtected"
                    onClick={() => changeErrorMail(false)}
                  />
                </div>
              </>
            ) : (
              <div className="col-12">
                <form
                  noValidate
                  className="row"
                  onSubmit={evt => onClickVerify(evt)}
                >
                  <div className="input col-12">
                    <Input
                      id="email"
                      label="Email"
                      type="email"
                      placeholder="Enter your email"
                      value={value}
                      onChange={onChange}
                      error={inputError}
                    />
                  </div>
                  <div className="col-12">
                    <Button
                      label={lblVerify}
                      size="medium"
                      onClick={onClickVerify}
                      loading={loading}
                    />
                  </div>
                </form>
              </div>
            )
          }
        </div>
      </div>
    </div>
  </div>
)

export default Auth
